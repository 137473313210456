<!--
File: Roles.vue
Description: show list of roles entered in the DB.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="$t('label.search_records')"
              v-model="searchQuery"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="addRole"> {{ $t('buttons.add') }} </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-table :value="roles" @md-selected.native="onSelect" :md-sort.sync="currentSort" md-fixed-header
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('stdCols.id')" md-sort-by="roleId">
            {{ item.roleId }}
          </md-table-cell>
          <md-table-cell :md-label="$t('stdCols.name')" md-sort-by="roleName">
            {{ item.roleName }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.active')" md-sort-by="active">
            {{ item.active }}
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div>
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="handleEdit(item.id)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                @click.stop.prevent="handleDelete(item)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>
<script>
  import { Pagination } from '@/components'
  import { customSortMixin } from '@/mixins/customSortMixin'
  import messagesMixin from '@/mixins/messagesMixin'
  import Fuse from 'fuse.js'

  export default {
    name: 'roles-list',
    mixins: [customSortMixin, messagesMixin],

    data() {
      return {
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['roleName'],
        searchedData: [],
        currentSort: 'roleName',
        currentSortOrder: 'asc',
        fuseSearch: null,
      }
    },

    components: {
      Pagination
    },

    mounted() {
      this.fuseSearch = new Fuse(this.roles, {
        keys: this.propsToSearch,
        threshold: 0.3
      })
    },

    methods: {
      handleEdit(id) {
        this.successMessage('Edit role', 'Saved')
      },

      addRole() {
        this.errorMessage('Could not add new role')
      },

      handleDelete(item) {
        this.deletedMessage(item.roleName)
      }
    },

    computed: {
      roles() {
        return [
          { roleId: 1, roleName: 'Admin', active: true },
          { roleId: 2, roleName: 'Entry group', active: true },
          { roleId: 3, roleName: 'Viewers', active: true },
          { roleId: 4, roleName: 'Guests', active: true },
        ]
      },

      amIAdmin() {
        return this.$store.getters.amIAdmin
      },
      to() {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total() {
        return this.searchQuery ? this.searchedData.length : this.roles.length
      },
    },

    watch: {
      searchQuery(value) {
        if (this.unitList.length == 0) {
          this.searchedData = []
          return
        }
        this.searchedData = (value !== '') ? this.fuseSearch.search(this.searchQuery) : this.roles
      },
    }
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-card {
  margin: 0px 0;
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>